import {
  createJsonBodyRequestConfig,
  RequestConfig
} from 'packages/http-client/fetcher';

export enum ConfigurationItem {
  adobeLaunchUrl = 'adobe.launch.url',
  iovationUrl = 'iovation.url',
  deviceDataUrl = 'cardinalcruise.collect.url',
  iovationV2Enabled = 'iovation.v2',
  googleCaptchaApiKey = 'google.captcha.api.key',
  googleMapsApiKey = 'google.maps.api.key',
  bazaarvoiceContainer = 'bazaarvoice.container',
  idCheckTimeoutSeconds = 'id.check.timeout.seconds',
  idMonitoringUrl = 'idMonitoring.url',
  acquisitionUrl = 'acquisition.url',
  hideAppbarLogo = 'hide.appbar.logo',
  navbarItems = 'navbar.items',
  rtMovemoneyMenu = 'rt.movemoney.menu',
  surveyFeedbackUrl = 'survey.feedback.url',
  tilesSelfOfferPromotionUrl = 'tiles.selfofferpromotion.url',
  tilesNSCVSMerchantRewardsUrl = 'tiles.nscvsmerchantrewards.url',
  tilesNSWALMARTMerchantRewardsUrl = 'tiles.walmartmerchantrewardspromotion.url',
  libertyTaxSeasonTaxServicesUrl = 'dashboard.taxservice.url',
  tilesClickToPayUrl = 'tiles.clickToPay.url',
  eulaEnabled = 'eula.enabled',
  debitCardTransferLimits = 'debitCardTransfer.limits',
  securityInfoUrl = 'security.info.url',
  termsEulaUrl = 'terms.eula.url',
  termsPrivacyPolicyUrl = 'terms.privacy_policy.url',
  tilesRaf40LimitedDoublePromoUrl = 'tiles.raf40limiteddoublepromo.url',
  tilesNSBigLotsMerchantRewardsUrl = 'tiles.nsbiglotsmerchantrewards.url',
  tilesNS1800FlowersMerchantRewardsUrl = 'tiles.ns1800flowersmerchantrewards.url',
  tilesNSCostcoMerchantRewardsPromotionUrl = 'tiles.nscostcomerchantrewardspromotion.url',
  tilesNSFinishLineMerchantRewardsPromoUrl = 'tiles.nsfinishlinemerchantrewardspromo.url',
  tilesNsJdSportsMerchantRewardsQ4235PromotionUrl = 'tiles.nsjdsportsmerchantrewardsq4235promotion.url',
  tilesNSCostcoMerchantRewardsPromoQ423For2PercUrl = 'tiles.nscostcomerchantrewardspromoQ423for2perc.url',
  tilesNSJCPenneyMerchantRewardsPromoQ423For4PercUrl = 'tiles.nsjcpenneymerchantrewardspromoq423for4perc.url',
  tilesNSAttMerchantRewardsQ124PromotionUrl = 'tiles.nsattmerchantrewardsq124promotion.url'
}

export interface GetConfigurationResponse {
  [ConfigurationItem.adobeLaunchUrl]: string;
  [ConfigurationItem.iovationUrl]: string;
  [ConfigurationItem.deviceDataUrl]: string;
  [ConfigurationItem.iovationV2Enabled]?: boolean;
  [ConfigurationItem.googleCaptchaApiKey]: string;
  [ConfigurationItem.googleMapsApiKey]?: string;
  [ConfigurationItem.bazaarvoiceContainer]: string;
  [ConfigurationItem.idCheckTimeoutSeconds]: number;
  [ConfigurationItem.idMonitoringUrl]: string;
  [ConfigurationItem.acquisitionUrl]: string;
  [ConfigurationItem.hideAppbarLogo]: boolean;
  [ConfigurationItem.navbarItems]: string;
  [ConfigurationItem.rtMovemoneyMenu]: string;
  [ConfigurationItem.surveyFeedbackUrl]: string;
  [ConfigurationItem.tilesSelfOfferPromotionUrl]: string;
  [ConfigurationItem.tilesNSCVSMerchantRewardsUrl]: string;
  [ConfigurationItem.tilesNSWALMARTMerchantRewardsUrl]: string;
  [ConfigurationItem.tilesClickToPayUrl]: string;
  [ConfigurationItem.libertyTaxSeasonTaxServicesUrl]: string;
  [ConfigurationItem.eulaEnabled]: boolean;
  [ConfigurationItem.debitCardTransferLimits]: string;
  [ConfigurationItem.securityInfoUrl]?: string;
  [ConfigurationItem.termsEulaUrl]?: string;
  [ConfigurationItem.termsPrivacyPolicyUrl]?: string;
  [ConfigurationItem.tilesRaf40LimitedDoublePromoUrl]?: string;
  [ConfigurationItem.tilesNSBigLotsMerchantRewardsUrl]?: string;
  [ConfigurationItem.tilesNS1800FlowersMerchantRewardsUrl]?: string;
  [ConfigurationItem.tilesNSCostcoMerchantRewardsPromotionUrl]?: string;
  [ConfigurationItem.tilesNSFinishLineMerchantRewardsPromoUrl]?: string;
  [ConfigurationItem.tilesNsJdSportsMerchantRewardsQ4235PromotionUrl]?: string;
  [ConfigurationItem.tilesNSCostcoMerchantRewardsPromoQ423For2PercUrl]?: string;
  [ConfigurationItem.tilesNSJCPenneyMerchantRewardsPromoQ423For4PercUrl]?: string;
  [ConfigurationItem.tilesNSAttMerchantRewardsQ124PromotionUrl]?: string;
}

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2GetFrontEndConfiguration
 */
export const createGetConfigurationRequest = (): RequestConfig<
  GetConfigurationResponse
> => ({
  url: '/v2/configuration',
  method: 'GET',
  skipAuth: true // used in activation, will be removed during SP-2324
});

/**
 * https://confluence.corp.netspend.com/display/proddev/WebApiV2PostFrontEndConfiguration
 */
export const createPostConfigurationRequest = (
  items: ConfigurationItem[]
): RequestConfig<Partial<GetConfigurationResponse>> =>
  createJsonBodyRequestConfig({
    url: '/v2/configuration',
    method: 'POST',
    mutating: false,
    json: { items }
  });
